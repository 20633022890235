import React, { useState } from "react";
import { useCountdown } from "hooks/useCountdown";
import DateTimeDisplay from "./DateTimeDisplay";

const ExpiredNotice = () => {
  return (
    <div className="border border-solid border-[#ebebeb] rounded-sm p-5 sm:p-8 m-2">
      <p className="text-xl sm:text-2xl text-red font-opensans font-semibold text-center">
        Due date has passed
      </p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds, title }) => {
  return (
    <div className="md:p-4">
      {title && (
        <h2 className="text-3xl sm:text-5xl text-darkBlue font-secondary font-semibold text-center pb-14">
          Time left
        </h2>
      )}
      <div className="flex items-center justify-center md:gap-5 font-secondary font-semibold border border-solid bordfer-[#ebebeb] rounded-sm p-4">
        <DateTimeDisplay value={days} type={"Days"} isDanger={days <= 3} />
        <p>:</p>
        <DateTimeDisplay value={hours} type={"Hours"} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={minutes} type={"Mins"} isDanger={false} />
        <p>:</p>
        <DateTimeDisplay value={seconds} type={"Seconds"} isDanger={false} />
      </div>
    </div>
  );
};

const CountdownTimer = ({ targetDate, formattedDateUtc }) => {
  const [title, setTitle] = useState(true);
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  if (days + hours + minutes + seconds <= 0) {
    setTitle(false);
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
        title={title}
      />
    );
  }
};

export default CountdownTimer;
