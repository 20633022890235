import React from "react";
import Layout from "components/layout";
import FinalTimer from "components/Timer/FinalTimer";
import SEO from "components/seo";

function Index() {
  return (
    <>
      <Layout>
        <SEO
          title="Project Time Tracker - Free Time Tracking App"
          description="Manage your projects more efficiently with our free and user-friendly time tracker. Our app will help you meet your deadlines with ease."
          slug="/project-timer"
        />
        <FinalTimer />
      </Layout>
    </>
  );
}

export default Index;
