import React from "react";

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div
      className={
        isDanger
          ? "flex flex-col items-center px-3 text-darkBlue"
          : "flex flex-col items-center px-3 text-darkBlue"
      }
    >
      <p className="text-xl sm:text-2xl font-secondary font-semibold">
        {value}
      </p>
      <span className="text-base sm:text-lg font-secondary uppercase">
        {type}
      </span>
    </div>
  );
};

export default DateTimeDisplay;
